import { makeComparator } from '@/helpers/helpers';
import { getIdb, idbHelpers } from '@/idb';
import User from '@/models/User';
import { DateTime } from 'luxon';
import { fetchWrap, idbResponse, isIdbResponse, offlineResponse } from '../_helpers';
import cache from './cache';

const idbStore = 'users';

export default {
	/**
	 * Get all users, filtered by the following parameters.
	 * @param {Object} params
	 * @param {boolean} params.active
	 * @param {boolean} params.operator
	 * @returns (async) Returns a Response if the request was made without network errors, or undefined if a network error occurred.
	 */
	async getAll({ active = undefined, operator = undefined } = {}) {
		const filters = {};
		if (typeof active === 'boolean') {
			filters.active = active;
		}
		if (typeof operator === 'boolean') {
			filters.operator = operator;
		}
		const idb = await getIdb();
		let response, data = [], timestamp;
		let useIdb = await cache.isCacheHit(idbStore);
		if (!useIdb) {
			try {
				timestamp = DateTime.now();
				response = await fetchWrap('/api/Users');
				if (response.ok) { data = await response.json(); }
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		const filter = Object.keys(filters).length > 0 ? (
			x => (!('active' in filters) || x.active === filters.active) && (!('operator' in filters) || x.operator === filters.operator)
			) : undefined;
		if (useIdb && idb) {
			if (filter) {
				data = await idbHelpers.getFiltered(idb, idbStore, filter);
			} else {
				data = await idb.getAll(idbStore);
			}
			response = idbResponse(200);
		}
		if (response.ok) {
			if (!isIdbResponse(response)) {
				if (idb) {
					await idbHelpers.replaceAll(idb, idbStore, data);
					await cache.setTimestamp(idbStore, timestamp);
				}
				if (filter) {
					data = data.filter(filter);
				}
			}
			data.sort(makeComparator('firstName', 'lastName'));
			return data.map(x => new User(x));
		} else {
			throw response;
		}
	},
	/**
	 * Get a user
	 * @param {User} id User ID
	 * @returns (async) Returns a User if the request was successful, otherwise a Response.
	 */
	async getById(id) {
		const idb = await getIdb();
		let response, data = null;
		let useIdb = await cache.isCacheHit(idbStore);
		if (!useIdb && await cache.canCache()) {
			// cache all for future re-use
			if ((data = (await this.getAll()).find(x => x.id === id) ?? null)) {
				return data;
			} else {
				throw idbResponse(404);
			}
		}
		if (!useIdb) {
			try {
				response = await fetchWrap('/api/Users/' + id);
				if (response.ok) { data = await response.json(); }
			} catch {
				useIdb = true;
				response = offlineResponse();
			}
		}
		if (useIdb && idb) {
			data = await idb.get(idbStore, id);
			response = data ? idbResponse(200) : idbResponse(404);
		}
		if (response.ok) {
			if (idb && !isIdbResponse(response)) {
				await idb.put(idbStore, data);
			}
			return new User(data);
		} else {
			throw response;
		}
	},
	/**
	 * Create a user
	 * @param {User} model user to create.
	 * @returns (async) Returns the new User if the request was successful, otherwise a Response.
	 */
	async create(model) {
		let response;
		try {
			response = await fetchWrap('/api/Users/', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			await cache.clearTimestamp(idbStore);
			const data = await response.json();
			return new User(data);
		} else {
			return response;
		}
	},
	/**
	 * Update a use
	 * @param {User} model user to update.
	 * @returns (async) Returns the updated User if the request was successful, otherwise a Response.
	 */
	async update(model) {
		let response;
		try {
			response = await fetchWrap('/api/Users/' + model.id, {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(model),
			});
		} catch {
			response = offlineResponse();
		}
		if (response.ok) {
			await cache.clearTimestamp(idbStore);
			const data = JSON.parse(JSON.stringify(model));
			return new User(data);
		} else {
			return response;
		}
	},
	/**
	 * Delete a user
	 * @param {Number} id User ID to delete.
	 * @returns (async) Returns true if the request was successful (or not found), false if the user could not be deleted, otherwise a Response.
	 */
	async deleteById(id) {
		let response;
		try {
			response = await fetchWrap('/api/Users/' + id, { method: 'DELETE' });
		} catch {
			return offlineResponse();
		}
		if (response.ok) {
			await cache.clearTimestamp(idbStore);
			return true;
		} else if (response.status === 404) {
			return true;
		} else if (response.status === 409) {
			return false;
		} else {
			return response;
		}
	},
	async resendInvite(id) {
		let response;
		try {
			response = await fetchWrap('/api/Users/' + id + '/resendsetupemail', { method: 'POST' });
		} catch {
			response = offlineResponse();
		}
		return response;
	},
};
